import Helmet from "react-helmet"
import React from "react"
import HomepageLayout from "src/layouts/homepage"
import NonbmA from "src/components/modules/nonbmA"
import CtaDisclosureSearch from "@tightrope/ctadisclosuresearch"
import {isIeEdge,isChrome,isFirefox} from "@tightrope/lpscripts/browserdetect"

const prdbestData = import("./data/data.json");

export default function nonbmtemplate() {

  let css;
  if(isFirefox()){
    css = `.nonbma-module--nonbmwrap ul.nonbma-module--bullets {
        padding-left: 151px;
        }`
      }

  return(
    <HomepageLayout>
      <Helmet>
<style type="text/css">{`

body {
background-image: url(/assets/index-bg.jpg);
background-color: #3d3d3d;
background-repeat: no-repeat;
min-height: 100%;
width: 100%;
padding: 0
margin: 0;
background-size: cover;
}

button.trbm_cta {
  font-weight: normal;
  font-size: 30px;
}

button {
  padding: 20px 50px;
  font-weight: normal;
  font-size: 35px !important;
  padding: 20px 100px  !important;
  border: none  !important;
  background: #43ba19  !important;
  border-radius: 10px  !important;
  display: table  !important;
  margin: 0px auto 5px  !important;
  cursor: pointer  !important;
  color: white  !important;
}

button:hover{
  transform: scale(1.2) !important;
}

.trbm-module--bottom h2,
.trbm-module--howto span, .footerlinks-module--br, .footerlinks-module--br a {
  color: #f1f1f1;
}

.trbm-module--bullets {
  width: 550px;
}

.cws {
  width: 200px ;
  display: block ;
  margin: 10px auto;
}

#ctadisclosuresearch-module--ctadisclaimersearch  {
  padding: 0px 24% ;
  padding-bottom: 0 ;
}

#ctadisclosure-module--ctadisclaimer {
  padding: 0px 7% ;
  padding-bottom: 0 ;
}

`}
   </style>

   <style type="text/css">
        {css}
            </style>

      <title>Freshy Search - freshysearch.com</title></Helmet>
      <NonbmA data={prdbestData}></NonbmA>
    </HomepageLayout>
  )
}
